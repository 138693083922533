<template>
  <PageWithLayout :isBodyBg="false">
    <BoxView title="table list" :grid="100">
      <TableList>
        <template v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">공지사항</strong>
            <p class="desc_tbl">공지사항 내역이 나옵니다</p>
          </div>
          <div class="area_right">
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="등록하기"/>
          </div>
        </template>
        <template v-slot:tbl_colgroup>
          <col style="width:55px"/>
          <col style="width:60px"/>
          <col style="width:20%"/>
          <col/>
          <col style="width:70px"/>
          <col style="width:80px"/>
        </template>
        <template v-slot:tbl_head>
          <th>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="CheckboxOnlyOne"/>
          </th>
          <th>No.</th>
          <th>제목</th>
          <th>내용</th>
          <th>고정여부</th>
          <th></th>
        </template>
        <template v-slot:tbl_body>
          <tbody>
            <tr
              v-for="index in 10"
              :key="`tr_${index}`">
              <td>
                <CheckboxOnlyOne
                  id="checkboxOnlyOne"
                  :value.sync="CheckboxOnlyOne"/>
              </td>
              <td>{{ 11 - index }}</td>
              <td class="td_ellip">공지사항 제목이 들어갑니다.</td>
              <td class="td_ellip">공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.</td>
              <td>Y</td>
              <td>
                <Button
                  btnSize="small"
                  btnStyle="secondary_border"
                  text="삭제"/>
              </td>
            </tr>
          </tbody>
        </template>
      </TableList>
    </BoxView>
    <BoxView title="table view" :grid="100">
      <TableView>
        <template v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">공지사항 등록</strong>
            <p class="desc_tbl">공지사항에 적용됩니다</p>
          </div>
          <div class="area_right">
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="미리보기"/>
          </div>
        </template>
        <template v-slot:tbl_head>
          <th colspan="2">head1</th>
          <th colspan="2">head2</th>
        </template>
        <template v-slot:tbl_body>
          <tr>
            <th>고정</th>
            <td>
              <SwitchCheck :value.sync="isFixed" />
            </td>
            <th>고정</th>
            <td>
              <span class="txt_tbl">{{ isFixed ? '고정' : '미고정' }}</span>
            </td>
          </tr>
          <tr>
            <th>제목<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
            <td>
              <Input
                placeholder="공지사항 제목을 입력해주세요."
                :value.sync="title"/>
            </td>
            <th>제목</th>
            <td>
              <span class="txt_tbl">공지사항 제목이 들어갑니다.</span>
            </td>
          </tr>
          <tr>
            <th>내용<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
            <td>
              <Textarea
                placeholder="공지사항 내용을 입력해주세요."
                :value.sync="content"
                :isCount="true"/>
            </td>
            <th>내용</th>
            <td>
              <span class="txt_tbl">공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.</span>
            </td>
          </tr>
        </template>
        <template v-slot:tbl_foot>
          <tr>
            <th>foot title</th>
            <td colspan="3">foot content</td>
          </tr>
        </template>
        <template v-slot:tbl_bottom>
          <ul class="list_info">
            <li>- 공지사항관련 안내문구가 들어갑니다</li>
            <li>- 공지사항관련 안내문구가 들어갑니다</li>
          </ul>
        </template>
      </TableView>
    </BoxView>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';

export default {
  name:'Table',
  components:{
    PageWithLayout,
    BoxView,
    TableList,
    CheckboxOnlyOne,
    TableView,
    IconSvg,
    Button,
    SwitchCheck,
    Input,
    Textarea,
  },
  data(){
    return{
      CheckboxOnlyOne:false,
      isFixed:false,
      title:'',
      content:''
    }
  }
}
</script>